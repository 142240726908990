import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@mui/material';

import Button from '@mui/material/Button';
import { Col, Row, Accordion } from 'react-bootstrap';

import useRouteGuard from '../../helpers/useRouteGuard'; // Update the path as needed
import NavbarMenu from '../../components/NavbarMenu/NavbarMenu';
import Avatar from '../../components/Avatar';
import BotResponse from '../../components/BotResponse';
import Error from '../../components/Error';
import IntroSection from '../../components/IntroSection';
import Loading from '../../components/Loading';
import SvgComponent from '../../components/SvgComponent';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';

const Prompting = () => {
  const redirectTo = '/data-uploading'; // Redirect path if access is denied
  const { isLoading, canProceed } = useRouteGuard();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const { keycloak } = useKeycloak();
  const [inputPrompt, setInputPrompt] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [chatLog, setChatLog] = useState([]);
  const [err, setErr] = useState(false);
  const [responseFromAPI, setReponseFromAPI] = useState(false);
  const [activeKey, setActiveKey] = useState(null);
  const chatLogRef = useRef(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!isLoading) {
      if (canProceed) {
        // Navigate to /prompting if access is allowed
        navigate('/prompting');
      } else {
        // Open the dialog if access is denied
        setOpenDialog(true);
      }
    }
  }, [isLoading, canProceed, navigate]);

  const handleConfirmDialog = () => {
    navigate(redirectTo);
    setOpenDialog(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!suggestions.includes(inputPrompt)) {
      const updatedPrompts = [...suggestions, inputPrompt];
      localStorage.setItem('prompts', JSON.stringify(updatedPrompts));
      setSuggestions(updatedPrompts); // Update state with the new prompts
    }

    if (!responseFromAPI) {
      if (inputPrompt.trim() !== '') {
        setReponseFromAPI(true);
        setChatLog([...chatLog, { chatPrompt: inputPrompt }]);
        callAPI();
        e.target.querySelector('input').blur();
      }

      async function callAPI() {
        if (!keycloak || !keycloak.authenticated) {
          return;
        }
        const accessToken = keycloak.token;
        const model = localStorage.getItem('selectedModel') || '';
        try {
          const response = await fetch(`${process.env.NODE_ENV === 'development' ? 'http://localhost:4000' : '/api'}/openai`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`,
              'X-Model': model
            },
            body: JSON.stringify({ query: inputPrompt, locale: i18n.language }),
          });
          const data = await response.json();
          setChatLog([
            ...chatLog,
            {
              chatPrompt: inputPrompt,
              botMessage: data.query,
              queryResult: data.queryResult,
              llmModel: model
            },
          ]);
          setErr(false);
        } catch (err) {
          setErr(err);
          console.log(err);
        }
        setReponseFromAPI(false);
      }
    }

    setInputPrompt('');
    setShowSuggestions(false);
  };

  useEffect(() => {
    const storedPrompts = JSON.parse(localStorage.getItem('prompts')) || [];
    setSuggestions(storedPrompts);
  }, []);

  const handleSuggestionClick = (suggest) => {
    setInputPrompt(suggest);
    setShowSuggestions(false);
  };

  useEffect(() => {
    if (chatLogRef.current) {
      chatLogRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [chatLog]);

  useEffect(() => {
    if (chatLog.length > 0) {
      setActiveKey(chatLog.length - 1);
    }
  }, [chatLog]);

  const handleAccordionSelect = (newActiveKey) => {
    setActiveKey(newActiveKey);
  };

  return (
    <>
      <NavbarMenu />
      {isLoading ? (
        <CircularProgress style={{ display: 'block', margin: 'auto' }} />
      ) : canProceed ? (
        <section className="chatBox">
          {chatLog.length > 0 ? (
            <div className="chatLogWrapper">
              <Accordion activeKey={activeKey} onSelect={handleAccordionSelect}>
                {chatLog.map((chat, idx) => (
                  <Accordion.Item
                    className="chatLog"
                    key={idx}
                    eventKey={idx}
                    ref={chatLogRef}
                    id={`navPrompt-${chat.chatPrompt.replace(/[^a-zA-Z0-9]/g, '-')}`}
                  >
                    <Accordion.Header>
                      <div className="chatPromptMainContainer">
                        <div className="chatPromptWrapper" style={{ padding: '0 !important' }}>
                          <Avatar bg="#005b7f" className="userSVG">
                            <svg
                              stroke="white"
                              fill="none"
                              strokeWidth={1.9}
                              viewBox="0 0 24 24"
                              className="h-6 w-6"
                              height={40}
                              width={40}
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                              <circle cx={12} cy={7} r={4} />
                            </svg>
                          </Avatar>
                          <div id="chatPrompt">{chat.chatPrompt}</div>
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body style={{ padding: 0 }}>
                      <div className="botMessageMainContainer">
                        <div className="botMessageWrapper">
                          <Avatar bg="#11a27f" className="openaiSVG">
                            <SvgComponent w={41} h={41} />
                          </Avatar>
                          {chat.botMessage ? (
                            <div id="botMessage">
                              <BotResponse
                                response={chat.botMessage}
                                chatLogRef={chatLogRef}
                                queryResponse={chat.queryResult}
                                question={chat.chatPrompt}
                                llmModel={chat.llmModel}
                              />
                            </div>
                          ) : err ? (
                            <Error err={err} />
                          ) : (
                            <Loading />
                          )}
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
                <br />
              </Accordion>
            </div>
          ) : (
            <IntroSection />
          )}

          <form onSubmit={handleSubmit}>
            {chatLog.length === 0 && (
              <div>
                <p>{t("menu3_prompt_sample_ques")}</p>
                <div>
                  <Row>
                    <Col className="d-grid gap-2" md={2} xs={0}></Col>
                    <Col className="d-grid gap-2" md={4} xs={6}>
                      <Button variant="light" onClick={() => setInputPrompt(document.querySelector('#q1').textContent)}>
                        <div className='text-font'>
                          <div style={{ marginTop: 10 }} id="q1"><p><strong>{t("menu3_prompt_explain_dataset_title")}</strong></p> <p>{t("menu3_prompt_explain_dataset_des")}</p></div>
                        </div>
                      </Button>
                    </Col>
                    <Col className="d-grid gap-2" md={4} xs={6}>
                      <Button variant="light" onClick={() => setInputPrompt(document.querySelector('#q2').textContent)}>
                        <div className='text-font'>
                          <div style={{ marginTop: 10 }} id="q2"><p><strong>{t("menu3_prompt_rows_cnt")}</strong></p> <p>{t("menu3_prompt_rows_cnt_inst")}</p></div>
                        </div>
                      </Button>
                    </Col>
                    <Col className="d-grid gap-2" md={2} xs={0}></Col>
                    {/* Repeat the structure for other buttons */}
                  </Row>
                  <br />
                  <br />
                  <Row>
                    <Col className="d-grid gap-2" md={2} xs={0}></Col>
                    <Col className="d-grid gap-2" md={4} xs={6}>
                      <Button variant="light" onClick={() => setInputPrompt(document.querySelector('#q3').textContent)}>
                        <div className='text-font'>
                          <div style={{ marginTop: 10 }} id="q3"><p><strong>{t("menu3_prompt_selling_products")}</strong></p> <p>{t("menu3_prompt_selling_top_products")}</p></div>
                        </div>
                      </Button>
                    </Col>
                    <Col className="d-grid gap-2" md={4} xs={6}>
                      <Button variant="light" onClick={() => setInputPrompt(document.querySelector('#q4').textContent)}>
                        <div className='text-font'>
                          <div style={{ marginTop: 10 }} id="q4"><p><strong>{t("menu3_prompt_selling_category")}</strong></p> <p>{t("menu3_prompt_selling_category_list")}</p></div>
                        </div>
                      </Button>
                    </Col>
                    <Col className="d-grid gap-2" md={2} xs={0}></Col>
                    {/* Repeat the structure for other buttons */}
                  </Row>
                </div>
              </div>
            )}

            <div className="inputPromptWrapper">
              <input
                name="inputPrompt"
                id=""
                autoComplete="off"
                className="inputPrompttTextarea"
                type="text"
                rows="1"
                value={inputPrompt}
                onChange={(e) => setInputPrompt(e.target.value)}
                onClick={() => setShowSuggestions(true)}
                onBlur={() => setShowSuggestions(false)} // Hide suggestions on blur
                autoFocus
                placeholder={t("menu3_new_prompt")}
              ></input>
              <button aria-label="form submit" type="submit">
                <svg
                  style={{ rotate: '60deg' }}
                  fill="#005B7F"
                  width={25}
                  // height={35}
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#005B7F"
                  strokeWidth={0}
                >
                  <title>{"submit form"}</title>
                  <path
                    d="m30.669 1.665-.014-.019a.73.73 0 0 0-.16-.21h-.001c-.013-.011-.032-.005-.046-.015-.02-.016-.028-.041-.05-.055a.713.713 0 0 0-.374-.106l-.05.002h.002a.628.628 0 0 0-.095.024l.005-.001a.76.76 0 0 0-.264.067l.005-.002-27.999 16a.753.753 0 0 0 .053 1.331l.005.002 9.564 4.414v6.904a.75.75 0 0 0 1.164.625l-.003.002 6.259-4.106 9.015 4.161c.092.043.2.068.314.068H28a.75.75 0 0 0 .747-.695v-.002l2-27.999c.001-.014-.008-.025-.008-.039l.001-.032a.739.739 0 0 0-.073-.322l.002.004zm-4.174 3.202-14.716 16.82-8.143-3.758zM12.75 28.611v-4.823l4.315 1.992zm14.58.254-8.32-3.841c-.024-.015-.038-.042-.064-.054l-5.722-2.656 15.87-18.139z"
                    stroke="none"
                  />
                </svg>
              </button>

              {/* Suggestions */}
              {showSuggestions && (
                <div className="suggestionsWrapper">
                  <div className="suggestions">
                    {suggestions.slice().reverse().map((suggest, index) => (
                      <div key={index} className="suggestion inputPrompttTextarea" onMouseDown={() => handleSuggestionClick(suggest)}>
                        {suggest}
                      </div>
                    ))}
                  </div>
                </div>
              )}

            </div>
          </form>

        </section>
      ) : (
        <Dialog open={openDialog}>
          <DialogTitle>{t('uploadCsvRequired')}</DialogTitle>
          <DialogContent>
            <p>{t('uploadCsvInstruction')}</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmDialog} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default Prompting;
